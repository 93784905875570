:root {
    --primary-color: #6558FF;
    --secondary-color: #37b3ed;
    --text-color: #272829;
    --bg-color: #f5f8fd;
    --section-bg-color: #fff;
    --transition-fast: 0.3s;
    --transition-medium: 0.5s;
    --transition-slow: 0.8s;
    --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --box-shadow-hover: 0 8px 25px rgba(0, 0, 0, 0.2);
}

/* Global Styles */
body {
    font-family: "Open Sans", sans-serif;
    color: var(--text-color);
    background-color: var(--bg-color);
    overflow-x: hidden;
}

a {
    color: var(--primary-color);
    text-decoration: none;
    transition: all var(--transition-fast) ease;
}

a:hover {
    color: var(--secondary-color);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
}

section {
    padding: 60px 0;
    overflow: hidden;
    transition: all var(--transition-medium) ease;
}

.section-bg {
    background-color: var(--section-bg-color);
}

.section-title {
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: var(--primary-color);
}

.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--primary-color);
    bottom: 0;
    left: 0;
    transition: width var(--transition-medium) ease;
}

.section-title:hover h2::after {
    width: 100px;
}

.section-title p {
    margin-bottom: 0;
}

/* Back to top button */
.back-to-top {
    position: fixed;
    visibility: visible;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: var(--primary-color);
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all var(--transition-fast);
    box-shadow: var(--box-shadow);
}

.back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: var(--secondary-color);
    box-shadow: var(--box-shadow-hover);
    transform: translateY(-3px);
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    transition: all ease-in-out 0.5s;
    z-index: 9997;
    transition: all 0.5s;
    padding: 0 15px;
    background: #040b14;
    overflow-y: auto;
}

#header .profile img {
    margin: 15px auto;
    display: block;
    width: 120px;
    border: 8px solid #2c2f3f;
}

#header .profile h1 {
    font-size: 24px;
    margin: 0;
    padding: 0;
    font-weight: 600;
    -moz-text-align-last: center;
    text-align-last: center;
    font-family: "Poppins", sans-serif;
}

#header .profile h1 a,
#header .profile h1 a:hover {
    color: #fff;
    text-decoration: none;
}

#header .social-links {
    display: flex;
    justify-content: center;
}

#header .profile .social-links a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background: #212431;
    color: #fff;
    line-height: 1;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#header .profile .social-links a:hover {
    background: #149ddd;
    color: #fff;
    text-decoration: none;
}

#main {
    margin-left: 300px;
}

.container,
.container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}

@media (max-width: 1199px) {
    #header {
        left: -300px;
    }

    #main {
        margin-left: 0;
    }

    .container,
    .container-fluid {
        padding-left: 12px;
        padding-right: 12px;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
    padding: 30px 0 0 0;
}

.nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu>ul>li {
    position: relative;
    white-space: nowrap;
}

.nav-menu a,
.nav-menu a:focus {
    display: flex;
    align-items: center;
    color: #a8a9b4;
    padding: 12px 15px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
}

.nav-menu a i,
.nav-menu a:focus i {
    font-size: 24px;
    padding-right: 8px;
    color: #6f7180;
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu .active:focus,
.nav-menu li:hover>a {
    text-decoration: none;
    color: #fff;
}

.nav-menu a:hover i,
.nav-menu .active i,
.nav-menu .active:focus i,
.nav-menu li:hover>a i {
    color: #6558FF;
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9998;
    border: 0;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    background-color: #149ddd;
    color: #fff;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    border-radius: 50px;
    cursor: pointer;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active #header {
    left: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero_content {
    width: 100%;
    height: 100vh;
    background: url("/public/assets/hero-bg.jpg") top center;
    background-size: cover;
    position: relative;
}

#hero_content:before {
    content: "";
    background: rgba(5, 13, 24, 0.3);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

#hero_content .hero-container {
    position: relative;
    z-index: 2;
    min-width: 300px;
    padding: 0 15px;
}

#hero_content h1 {
    margin: 0 0 10px 0;
    font-size: 64px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
}

#hero_content p {
    color: #fff;
    margin-bottom: 50px;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
}

.hire_now_btn {
    background: var(--primary-color);
    border: none;
    padding: 10px 30px;
    border-radius: 50px;
    transition: all var(--transition-fast);
    box-shadow: var(--box-shadow);
}

.hire_now_btn:hover {
    background: var(--secondary-color);
    box-shadow: var(--box-shadow-hover);
    transform: translateY(-3px);
}

#hero_content p span {
    color: #fff;
    padding-bottom: 4px;
    letter-spacing: 1px;
    border-bottom: 3px solid #6558FF;
}

@media (min-width: 1024px) {
    #hero_content {
        background-attachment: fixed;
    }
}

@media (max-width: 768px) {
    #hero_content h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero_content h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section-title {
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: var(--primary-color);
}

.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--primary-color);
    bottom: 0;
    left: 0;
}

.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about_section .content h3 {
    font-weight: 700;
    font-size: 26px;
    color: var(--primary-color);
}

.about_section .content ul {
    list-style: none;
    padding: 0;
}

.about_section .content ul li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.about_section .content ul strong {
    margin-right: 10px;
}

.about_section .content ul i {
    font-size: 16px;
    margin-right: 5px;
    color: var(--primary-color);
    line-height: 0;
}

.about_section .content p:last-child {
    margin-bottom: 0;
}

.about_section .content img {
    border-radius: 10px;
    transition: all var(--transition-medium);
}

.about_section .content img:hover {
    transform: scale(1.02);
    box-shadow: var(--box-shadow);
}

/*--------------------------------------------------------------
# Facts
--------------------------------------------------------------*/
.facts {
    padding-bottom: 30px;
}

.facts .count-box {
    padding: 30px;
    width: 100%;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    transition: all var(--transition-medium);
}

.facts .count-box:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow-hover);
}

.facts .count-box i {
    display: block;
    font-size: 36px;
    color: var(--primary-color);
    float: left;
    line-height: 0;
}

.facts .count-box span {
    font-size: 36px;
    line-height: 30px;
    display: block;
    font-weight: 700;
    color: var(--text-color);
    margin-left: 60px;
}

.facts .count-box p {
    padding: 15px 0 0 0;
    margin: 0 0 0 60px;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    color: #122f57;
}

.facts .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #122f57;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    transition: ease-in-out 0.3s;
}

.facts .count-box a:hover {
    color: #1f5297;
}

/*--------------------------------------------------------------
# Akills
--------------------------------------------------------------*/
.skills .progress {
    height: 60px;
    display: block;
    background: none;
    border-radius: 0;
}

.skills .progress .skill {
    padding: 10px 0;
    margin: 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: var(--text-color);
}

.skills .progress .skill .val {
    float: right;
    font-style: normal;
}

.skills .progress-bar-wrap {
    background: #dce8f8;
    height: 10px;
    border-radius: 5px;
}

.skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: all var(--transition-slow);
    background-color: var(--primary-color);
    border-radius: 5px;
}

/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume_content .resume-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #050d18;
}

.resume_content .resume-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid #6558FF;
    position: relative;
}

.resume_content .resume-item h4 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #050d18;
    margin-bottom: 10px;
}

.resume_content .resume-item h5 {
    font-size: 16px;
    background: #e4edf9;
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
}

.resume_content .resume-item ul {
    padding-left: 20px;
}

.resume_content .resume-item ul li {
    padding-bottom: 10px;
}

.resume_content .resume-item:last-child {
    padding-bottom: 0;
}

.resume_content .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #6558FF;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.slick-arrow {
    color: #000000;
}

.slick-arrow:before {
    color: #000000 !important;
}

@media (max-width: 768px) {
    .slick-next {
        right: 0 !important;
    }

    .slick-prev {
        left: 0 !important;
        z-index: 1;
    }
}

.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px;
    margin: 0 10px 30px;
    box-shadow: var(--box-shadow);
    background: #fff;
    border-radius: 10px;
    transition: all var(--transition-medium);
}

.testimonials .testimonial-item:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow-hover);
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto 15px auto;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: var(--text-color);
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: #c3e8fa;
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
}

.testimonials .testimonial-item p::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid #fff;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 20px);
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #149ddd;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #149ddd;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
    padding-bottom: 130px;
}

.contact .info {
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    transition: all var(--transition-medium);
}

.contact .info:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow-hover);
}

.contact .info i {
    font-size: 20px;
    color: var(--primary-color);
    float: left;
    width: 44px;
    height: 44px;
    background: #dff3fc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all var(--transition-fast);
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--text-color);
}

.contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #173b6c;
}

.contact .info .email p {
    padding-top: 5px;
}

.contact .info .social-links {
    padding-left: 60px;
}

.contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
}

.contact .info .social-links a:hover {
    background: #149ddd;
    color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
    background: var(--primary-color);
    color: #fff;
}

.contact .php-email-form {
    width: 100%;
    padding: 30px;
    background: #fff;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    transition: all var(--transition-medium);
}

.contact .php-email-form:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow-hover);
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .error-message br+br {
    margin-top: 25px;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
    margin-bottom: 15px;
}

.contact .php-email-form label {
    padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 5px;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 15px;
}

.contact .php-email-form button[type=submit] {
    background: var(--primary-color);
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: all var(--transition-fast);
    border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
    background: var(--secondary-color);
    transform: translateY(-3px);
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 20px 0;
    background: #f9f9f9;
}

.breadcrumbs h2 {
    font-size: 26px;
    font-weight: 300;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #0e2442;
    content: "/";
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    padding: 15px;
    color: #f4f6fd;
    font-size: 14px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 9999;
    background: #040b14;
}

#footer .copyright {
    text-align: center;
}

#footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #eaebf0;
}

@media (max-width: 1199px) {
    #footer {
        position: static;
        width: auto;
        padding-right: 20px 15px;
    }
}


.nav-menu_icon {
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 999;
}

@media (max-width: 1199px) {
    .nav-menu_icon {
        display: block;
    }
}

.left_active {
    left: 0 !important;
}

/* Animation Keyframes */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

/* Animation Classes */
.animate-fadeIn {
    animation: fadeIn var(--transition-medium) ease forwards;
}

.animate-slideInUp {
    animation: slideInUp var(--transition-medium) ease forwards;
}

.animate-pulse {
    animation: pulse 2s infinite;
}